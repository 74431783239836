import { IonContent, IonPage, IonList, IonItem, IonLabel, IonInput, IonButton, IonLoading, IonImg, IonHeader, IonAlert } from '@ionic/react';
import React from 'react';
import './Home.css';
import '../overall.css';

import Topbar from '../js/components/Topbar';
import { connect } from 'react-redux';
import {
    setUsername,
    setLoggedIn,
    setBasicAuth,
    setRoleAdmin,
    setRoleUser,
    setApiKey
} from '../js/redux/actions/useractions';

//TEST ANDROID DEPLOYMENT
//import 'capacitor-secure-storage-plugin';
import { Capacitor } from '@capacitor/core';
import { Plugins, StatusBarStyle } from '@capacitor/core';
import API from '../API';

//TEST ANDROID DEPLOYMENT
//const { StatusBar, Keyboard, SecureStoragePlugin, BiometricAuth } = Plugins;
let StatusBar;
let Keyboard;
if (Capacitor.platform !== "web") {
    StatusBar = Plugins.StatusBar;
    Keyboard = Plugins.Keyboard;
}

//const { StatusBar, Keyboard } = Plugins;

class Login extends React.Component {
    state = {
        username: '',
        password: '',
        showLoading: false,
        nextURL: '/revenue',
        showLoginError: false,
        showTouchIDAlert: false
    }

    async componentDidMount() {
        if (StatusBar) {
            StatusBar.setStyle({
                style: StatusBarStyle.Light
            });
        }

        if (Keyboard) {
            Keyboard.setAccessoryBarVisible({ isVisible: true });
        }

        //on mobile dont go to revenue
        //go to dashboard
        if (window.innerWidth < 900) {
            this.setState({ nextURL: '/dashboard' });
        }

        //are we redirected here from another page
        //to login? then bring the user back to this page 
        //after successed log in
        if (this.props.location.state && this.props.location.state.detail) {

            //delete '/#' from detail
            let nextURL_tmp = this.props.location.state.detail;
            if (nextURL_tmp[1] === '#') {
                nextURL_tmp = nextURL_tmp.substring(2);
            }
            this.setState({ nextURL: nextURL_tmp });
            console.log(nextURL_tmp);
        }

        if (this.props.loggedIn) {
            this.props.history.push('/revenue');
        }

        //TEST ANDROID DEPLOYMENT
        /*

        //are there already login credentials saved on this device? 
        //look up username
        let username = '';
        if ((await SecureStoragePlugin.getPlatform()).value !== 'web') {
            console.log('Smartphone erkannt');
            SecureStoragePlugin.get({ key: 'sundair-analytics-username' })
                .then(value => {
                    username = value.value;
                })
                .catch(error => {
                    //do nothing here
                });
        }

        let password = '';
        if ((await SecureStoragePlugin.getPlatform()).value !== 'web') {
            SecureStoragePlugin.get({ key: 'sundair-analytics-password' })
                .then(value => {
                    password = value.value;
                })
                .catch(error => {
                    //do nothing here
                });
        }

        const bioAvailable = await BiometricAuth.isAvailable();

        if (username.length > 0 && password.length > 0 && bioAvailable.has) {
            console.log('schnelles Anmelden verfügbar');
            //check biometric
            const authResult = await BiometricAuth.verify({
            });

            if (authResult.verified) {
                this.setState({ username: username, password: password });
                this._login({ buttonPressed: false });
            }
        }
        */
        //END TEST ANDROID DEPLOYMENT
    }

    async _login(o) {
        this.setState({ showLoading: true });

        let res = await new API().login(this.state.username.toUpperCase(), this.state.password);

        if (!res.success) {
            this.setState({ password: '', showLoginError: true });
        } else {
            //save infos to store
            this.props.setUsername(res.username);
            this.props.setBasicAuth(res.basicAuth);
            this.props.setRoleUser(res.grants.includes('catering.user'));
            this.props.setRoleAdmin(res.grants.includes('catering.admin'));
            this.props.setApiKey(res.apiKey);
            this.props.setLoggedIn(true);

            //TEST ANDROID DEPLOYMENT
            /*
            if (o.buttonPressed && (await SecureStoragePlugin.getPlatform()).value !== 'web') {
                this.setState({ showTouchIDAlert: true });
            } else {*/
            this.setState({ username: '', password: '', showLoginError: false });
            /*}*/
            //END TEST ANDROID DEPLOYMENT

            this.props.history.push(this.state.nextURL);
        }

        this.setState({ showLoading: false });
    }

    async _saveCredentials() {
        //TEST ANDROID DEPLOYMENT
        /*
        const bioAvailable = await BiometricAuth.isAvailable();

        if (bioAvailable.has) {
            //save login credentials for next login
            SecureStoragePlugin.set({ key: 'sundair-analytics-username', value: this.state.username });
            SecureStoragePlugin.set({ key: 'sundair-analytics-password', value: this.state.password });
        }

        this.setState({ username: '', password: '', showLoginError: false });
        */
        //END TEST ANDROID DEPLOYMENT
    }

    async _deleteCredentials() {
        //TEST ANDROID DEPLOYMENT
        /*
        SecureStoragePlugin.remove({ key: 'sundair-analytics-username' });
        SecureStoragePlugin.remove({ key: 'sundair-analytics-password' });

        this.setState({ username: '', password: '', showLoginError: false });
        */
        //END TEST ANDROID DEPLOYMENT
    }

    render() {
        return (
            <>
                <IonPage>
                    <IonHeader></IonHeader>
                    <IonContent fullscreen class='main-container'>
                        <Topbar
                            logoSize='big'
                            showBackButton={false}
                            showMenuButton={false}
                        />

                        <IonImg className='login-logo' src={'../../assets/sundairlogo.png'} />

                        <IonList class='login-container'>
                            {(this.state.showLoginError) ?
                                <p className='login-error'>
                                    <span>Fehler beim Anmelden.</span> Nutzername oder Passwort falsch.
                                </p>
                                : null}
                            <IonItem>
                                <IonLabel position='floating'>Nutzername</IonLabel>
                                <IonInput
                                    value={this.state.username}
                                    clearInput={true}
                                    inputMode='text'
                                    required={true}
                                    type='text'
                                    onIonChange={(e) => { this.setState({ username: e.detail.value }) }}>
                                </IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel
                                    position='floating'>Passwort</IonLabel>
                                <IonInput
                                    value={this.state.password}
                                    clearInput={true}
                                    required={true}
                                    type='password'
                                    onIonChange={(e) => { this.setState({ password: e.detail.value }) }}>
                                </IonInput>
                            </IonItem>
                        </IonList>
                        <IonButton
                            mode='ios'
                            disabled={this.state.username.length === 0 || this.state.password.length === 0}
                            class='button'
                            expand='full'
                            onClick={() => { this._login({ buttonPressed: true }) }}>
                            Login
                        </IonButton>
                        <p style={{ textAlign: 'center', marginTop: 64, fontSize: '0.8rem' }}>Version 1.0.4 (7)</p>
                    </IonContent>

                    <IonLoading
                        isOpen={this.state.showLoading}
                        cssClass='spinner'
                        spinner={null}
                        onDidDismiss={() => this.setState({ showLoading: false })} />
                </IonPage >
                <IonAlert
                    isOpen={this.state.showTouchIDAlert}
                    onDidDismiss={() => this.setState({ showTouchIDAlert: false })}
                    header={'Anmeldung'}
                    message={'Das nächste Mal mit TouchID/FaceID anmelden?'}
                    buttons={[
                        {
                            text: 'Nein',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                this._deleteCredentials();
                            }
                        },
                        {
                            text: 'Ja',
                            handler: () => {
                                this._saveCredentials();
                            }
                        }
                    ]}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.userinfos.loggedIn
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUsername: (username) => { dispatch(setUsername(username)) },
        setLoggedIn: (loggedIn) => { dispatch(setLoggedIn(loggedIn)) },
        setBasicAuth: (basicAuth) => { dispatch(setBasicAuth(basicAuth)) },
        setRoleAdmin: (isAdmin) => { dispatch(setRoleAdmin(isAdmin)) },
        setRoleUser: (isUser) => { dispatch(setRoleUser(isUser)) },
        setApiKey: (key) => { dispatch(setApiKey(key)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);